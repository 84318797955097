import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'pwm-components/styled';
import ThemeProvider from 'pwm-components/ThemeProvider';
import Box from 'pwm-components/components/Box';
import Card from 'pwm-components/components/Card';
import Text from 'pwm-components/components/Text';
import Paragraph from 'pwm-components/components/Paragraph';
import RoundTickIcon from 'pwm-components/icons/RoundTick';
import useResponsiveWidth from 'pwm-components/hooks/useResponsiveWidth';
import improve1x from '../../img/AbsImprove@1x.png';
import improve2x from '../../img/AbsImprove@2x.png';
import improve3x from '../../img/AbsImprove@3x.png';
import protect1x from '../../img/AbsGlobe@1x.png';
import protect2x from '../../img/AbsGlobe@2x.png';
import protect3x from '../../img/AbsGlobe@3x.png';
import ABSbackground from '../../img/ABSbackground.png';
import Footer from '../layout/Footer';
import Header from '../layout/Header';

import AddToButton from '../AddToButton';
import { ExtensionInstalledContext } from '../contexts/ExtensionInstallState';

const BackgroundContainer = styled('div')`
  position: relative;
  width: 100%;
  min-width: 375px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  @media only screen and (min-height: 1100px) {
   height: 100%;
  }
`;

const ABSBackgroundImage = styled('div')`
  position: absolute;
  bottom: calc(100% - 50px);
  z-index: -2;
  width: 100vw;
  height: 50vh;
  background-size: cover;
  background-image: url(${ABSbackground});
`;

const ABSOverlay = styled('div')`
  position: absolute;
  top: 50px;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-color: #333a3e;
`;

const InfoCard = styled(Card)<{ screenWrap?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: ${({ theme: { space } }) => space.xxs};
  margin: ${({ theme: { space } }) => space.xs};
  padding: ${({ theme: { space } }) => space.xxxl};
`;

const GetABS: React.FC = () => {
  const installed = useContext(ExtensionInstalledContext).abs;
  const screenWrap = useResponsiveWidth() === 'mobile';

  return (
    <ThemeProvider mode="dark">
      <BackgroundContainer>
        <Header />
        <Box
          m="auto"
          pb="xxxl"
          width="100%"
          display="flex"
          flex="1 0 auto"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Paragraph px="xxl" size="h1" mb="s" variant="normal" textAlign="center">
            <strong>
              <FormattedMessage
                id="getABS.header.getFullProtection"
                defaultMessage="Get full protection for your browser & privacy"
              />
            </strong>
          </Paragraph>
          <Paragraph px="xxl" size="h2" variant="normal" textAlign="center">
            <FormattedMessage
              id="getABS.header.movedToABS"
              defaultMessage="Tracking and ad-blocking moved to Avira Browser Safety"
            />
          </Paragraph>
          <Box
            mt="xl"
            px="xxl"
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box position="relative" display="flex" flexWrap="wrap" justifyContent="center">
              <ABSBackgroundImage />
              <ABSOverlay />
              <InfoCard width={screenWrap ? '100%' : '450px'}>
                <img
                  alt="improve"
                  height="70px"
                  src={improve1x}
                  srcSet={`${improve1x} 1x, ${improve2x} 2x, ${improve3x} 3x`}
                />
                <Paragraph my="m" size="h4" variant="normal" textAlign="center">
                  <strong>
                    <FormattedMessage
                      id="getABS.improvePrivacy.title"
                      defaultMessage="Improve your privacy"
                    />
                  </strong>
                </Paragraph>
                <Paragraph variant="disabled" textAlign="center">
                  <FormattedMessage
                    id="getABS.improvePrivacy.blockAds"
                    defaultMessage="Block ads and invisible trackers"
                  />
                </Paragraph>
              </InfoCard>
              <InfoCard width={screenWrap ? '100%' : '450px'}>
                <img
                  alt="protect"
                  height="70px"
                  src={protect1x}
                  srcSet={`${protect1x} 1x, ${protect2x} 2x, ${protect3x} 3x`}
                />
                <Paragraph my="m" size="h4" variant="normal" textAlign="center">
                  <strong>
                    <FormattedMessage
                      id="getABS.protectBrowsing.title"
                      defaultMessage="Protect your browsing"
                    />
                  </strong>
                </Paragraph>
                <Paragraph variant="disabled" textAlign="center">
                  <FormattedMessage
                    id="getABS.protectBrowsing.detectsAndWarn"
                    defaultMessage="Detects and warns you about malicious and phishing websites"
                  />
                </Paragraph>
              </InfoCard>
            </Box>
          </Box>
          <Box mt="xxl">
            {
              installed ? (
                <>
                  <RoundTickIcon variant="success" />
                  <Text ml="s" variant="normal">
                    <FormattedMessage
                      id="getABS.cto.absInstalled"
                      defaultMessage="Avira Browser Safety is installed"
                    />
                  </Text>
                </>
              ) : (
                <AddToButton variant="login" type="abs">
                  <Box px="m">
                    <FormattedMessage
                      id="getABS.cto.getABS"
                      defaultMessage="Get Avira Browser Safety"
                    />
                  </Box>
                </AddToButton>
              )
            }
          </Box>
        </Box>
        <Footer />
      </BackgroundContainer>
    </ThemeProvider>
  );
};

export default GetABS;
