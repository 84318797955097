enum LineHeightEnum {
  minion = '14px',
  bourgeois = '16px',
  primer = '17px',
  longPrimer = '20px',
  pica = '22px',
  english = '24px',
  paragon = '26px',
  greatPrimer = '28px',
  doubleColumbian = '32px',
  doubleSmallPica = '34px',
  doublePica = '36px',
  quadrupleColumbian = '64px',
  doubleEnglish = '72px',
  doubleGreatPrimer = '96px',
}

export type LineHeights = typeof LineHeightEnum;
export type LineHeightKey = keyof LineHeights;
export default LineHeightEnum;
