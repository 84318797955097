import { defineMessages } from 'react-intl';

export default defineMessages({
  'uninstall.whyUninstall.technicalIssue': {
    id: 'uninstall.whyUninstall.technicalIssue',
    defaultMessage: 'Technical issue (the extension didn\'t work)',
  },
  'uninstall.whyUninstall.notSimpleToUse': {
    id: 'uninstall.whyUninstall.notSimpleToUse',
    defaultMessage: 'It\'s not simple to use',
  },
  'uninstall.whyUninstall.doesNotSolve': {
    id: 'uninstall.whyUninstall.doesNotSolve',
    defaultMessage: 'It does not solve my problem (I don\'t need it)',
  },
  'uninstall.whyUninstall.willReinstall': {
    id: 'uninstall.whyUninstall.willReinstall',
    defaultMessage: 'I will reinstall the extension',
  },
  'uninstall.whyUninstall.usingDifferentTool': {
    id: 'uninstall.whyUninstall.usingDifferentTool',
    defaultMessage: 'I\'m using a different tool instead',
  },
  'uninstall.whyUninstall.tooExpensive': {
    id: 'uninstall.whyUninstall.tooExpensive',
    defaultMessage: 'It\'s too expensive',
  },
  'uninstall.whyUninstall.forgotMasterPassword': {
    id: 'uninstall.whyUninstall.forgotMasterPassword',
    defaultMessage: 'I forgot my master password',
  },
  'uninstall.whyUninstall.interferedTooMuch': {
    id: 'uninstall.whyUninstall.interferedTooMuch',
    defaultMessage: 'It interfered too much or was annoyinng',
  },
  'uninstall.whyUninstall.concernedSecurity': {
    id: 'uninstall.whyUninstall.concernedSecurity',
    defaultMessage: 'I\'m concerned about the security of my passwords',
  },
  'uninstall.whyUninstall.other': {
    id: 'uninstall.whyUninstall.other',
    defaultMessage: 'Other',
  },
  'uninstall.pleaseSelect': {
    id: 'uninstall.pleaseSelect',
    defaultMessage: 'Please select an option',
  },
  'uninstall.typeYourAnswer': {
    id: 'uninstall.typeYourAnswer',
    defaultMessage: 'Type your answer here',
  },
});
