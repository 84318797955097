import React, {
  useState, useContext, useEffect,
} from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';
import Text from 'pwm-components/components/Text';
import Paragraph from 'pwm-components/components/Paragraph';
import TextButton from 'pwm-components/components/TextButton';
import ChevronArrow from 'pwm-components/components/ChevronArrow';
import Dropdown from 'pwm-components/components/DropdownMenu/DropdownMenu';
import useResponsiveWidth from 'pwm-components/hooks/useResponsiveWidth';
import { DropdownMenuItem } from 'pwm-components/components/DropdownMenu';
import { languages, LocaleContext } from '../../locale/LocaleContainer';
import AviraLogo from '../../img/avira-logo.svg';
import SocialFacebook from '../../img/social_fb_light.svg';
import SocialTwitter from '../../img/social_tw_light.svg';
import SocialLinkedIn from '../../img/social_linkedin_light.svg';
import SocialPinterest from '../../img/social_pinterest_light.svg';
import SocialFacebookDark from '../../img/social_fb_dark.svg';
import SocialTwitterDark from '../../img/social_tw_dark.svg';
import SocialLinkedInDark from '../../img/social_linkedin_dark.svg';
import SocialPinterestDark from '../../img/social_pinterest_dark.svg';

const SocialMediaLink = styled('a')`
  margin-left: ${({ theme: { space } }) => space.l};
  margin-right: ${({ theme: { space } }) => space.l};
  display: inline-block;
  height: 30px;
`;

const SocialMediaLogo = styled('img')<{ light: string; dark: string }>`
  width: 30px;
  height: 30px;
  content: url(${({ theme: { mode }, light, dark }) => (mode === 'light' ? dark : light)});
`;

const FooterContainer = styled('div')`
  background-color: ${({ theme: { mode } }) => (mode === 'light' ? '#f9f9f9' : '#1b2428')};
`;

const EndFooter = styled('div')`
  padding-top: ${({ theme: { space } }) => space.l};
  padding-bottom: ${({ theme: { space } }) => space.l};
  background-color: ${({ theme: { mode } }) => (mode === 'light' ? '#454545' : '#12191b')};
`;

const BoxFooter = styled(Box)`
  :lang(ru){
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const LanguageDropup = styled(Dropdown)`
  left: 0;
  right: 0;
  top: unset;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: ${({ theme: { space } }) => space.xs};

  &::after {
    top: unset;
    right: ${({ theme: { space } }) => space.xs};
    transform: rotate(-45deg);
  }
`;

const findLocale = (loc: string): string => {
  const localeLowercase = loc.toLowerCase();
  const localeShort = localeLowercase.substring(0, 2);

  return (localeShort === 'pt' || localeShort === 'zh')
    ? localeLowercase
    : localeShort;
};

// eslint-disable-next-line complexity
const Footer: React.FC = () => {
  const { locale: currLocale, setLocale } = useContext(LocaleContext);
  const [showDropdown, setShowDropdown] = useState(false);


  useEffect(() => {
    const hideDropdown = (): void => {
      if (showDropdown) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('click', hideDropdown);
    }

    return () => document.removeEventListener('click', hideDropdown);
  }, [showDropdown]);

  const screen = useResponsiveWidth();
  let width = 0;
  let screenWrap = false;
  switch (screen) {
    case 'mobile':
      width = 720;
      screenWrap = true;
      break;
    case 'tablet':
      width = 960;
      break;
    default:
      width = 1180;
      break;
  }

  const StyledFooterLink = styled('a')`
    color: ${({ theme: { colors } }) => colors.white};
    margin-left: 20px;
  `;

  const FooterLink: React.FC<{ href: string; children: React.ReactNode }> = ({
    href,
    children,
  }) => (
    <StyledFooterLink target="_blank" rel="noopener noreferrer" href={href}>
      {children}
    </StyledFooterLink>
  );

  return (
    <FooterContainer>
      <Box
        pt="xxxl"
        pb="xxxl"
        px="xxl"
        m="auto"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxWidth={width}
      >
        <Box>
          <img src={AviraLogo} alt="Avira" style={{ width: 60, height: 60 }} />
        </Box>
        {
          !screenWrap && (
            <Box
              mt="xxxl"
              py="xl"
              display="flex"
              width="100%"
              borderTop
              borderBottom
              borderColor="gainsboro"
              justifyContent="center"
            >
              <SocialMediaLink href="https://www.facebook.com/avira" target="_blank">
                <SocialMediaLogo light={SocialFacebook} dark={SocialFacebookDark} alt="AviraFacebook" />
              </SocialMediaLink>
              <SocialMediaLink href="https://twitter.com/avira" target="_blank">
                <SocialMediaLogo light={SocialTwitter} dark={SocialTwitterDark} alt="AviraTwitter" />
              </SocialMediaLink>
              <SocialMediaLink href="https://www.linkedin.com/company/avira-gmbh/" target="_blank">
                <SocialMediaLogo light={SocialLinkedIn} dark={SocialLinkedInDark} alt="AviraLinkedIn" />
              </SocialMediaLink>
              <SocialMediaLink href="https://www.pinterest.com/avira/" target="_blank">
                <SocialMediaLogo light={SocialPinterest} dark={SocialPinterestDark} alt="AviraPinterest" />
              </SocialMediaLink>
            </Box>
          )
        }
      </Box>
      <EndFooter>
        <BoxFooter
          m="a"
          px="xxl"
          width="100%"
          display="flex"
          maxWidth={width}
          flexDirection={screenWrap ? 'column' : 'row'}
          lang={findLocale(currLocale)}
        >
          <Box mr="s" minWidth="150px" position="relative">
            <Button
              fullWidth
              variant="secondary"
              onClick={() => setShowDropdown(!showDropdown)}
              placeholder=""
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Text mr="xs" variant="light">
                {languages[currLocale as keyof typeof languages].language}
              </Text>
              <ChevronArrow color="light" direction={showDropdown ? 'down' : 'up'} />
            </Button>
            <LanguageDropup
              align="left"
              hidden={!showDropdown}
              arrowSize={3}
            >
              {
                Object.values(languages).map(
                  ({ locale, language }) => (
                    <DropdownMenuItem
                      key={locale}
                      variant="dark"
                    >
                      <TextButton onClick={() => setLocale(locale)}>
                        {language}
                      </TextButton>
                    </DropdownMenuItem>
                  ),
                )
              }
            </LanguageDropup>
          </Box>
          <Box
            display="flex"
            flex="1 1 auto"
            flexWrap="wrap"
            alignItems="center"
            justifyContent={screenWrap ? 'center' : 'flex-end'}
          >
            <Box mt={screenWrap ? 's' : '0'}>
              <FooterLink href={`https://www.avira.com/${findLocale(currLocale)}/general-imprint`}>
                <FormattedMessage id="footer.Imprint" defaultMessage="Imprint" />
              </FooterLink>
              <FooterLink href={`https://www.avira.com/${findLocale(currLocale)}/general-privacy`}>
                <FormattedMessage id="footer.Privacy" defaultMessage="Privacy" />
              </FooterLink>
              <FooterLink href={`https://www.avira.com/${findLocale(currLocale)}/legal-terms`}>
                <FormattedMessage id="footer.Legal" defaultMessage="Legal" />
              </FooterLink>
            </Box>
            <Paragraph
              size="small"
              variant="light"
              textAlign={screenWrap ? 'center' : 'right'}
              mt={screenWrap ? 's' : '0'}
              ml={screenWrap ? '0' : 'l'}
            >
              <FormattedMessage
                id="footer.Copyrighted"
                defaultMessage="© 2022  Avira Operations GmbH. All rights reserved."
              />
            </Paragraph>
          </Box>
        </BoxFooter>
      </EndFooter>
    </FooterContainer>
  );
};

export default Footer;
