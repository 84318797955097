import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';
import FontSizes from '../../../baseComponents/variables/fontSizes';
import { FontFamily, FontWeight } from '../../../baseComponents/variables/font';
import LineHeightEnum from '../../../baseComponents/variables/lineHeight';

export const FAQSectionContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.alabaster};
`;

export const FAQSectionHeading = styled('div')`
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.doubleGreatPrimer};
  line-height: ${LineHeightEnum.quadrupleColumbian};
  font-weight: ${FontWeight.bold};
  color: ${colors.shark};
  width: 968px;
  text-align: center;
  padding-top: 52px;
`;

export const FAQsContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  max-width: 1460px;
  padding-right: 120px;
  padding-left: 120px;
`;
