import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './components/App';


const Routing = () => (
  <Router>
    <Route path={['/:origin/:reason?/:timesShown?', '/']} component={App} />
  </Router>
);


ReactDOM.render(<Routing />, document.getElementById('root'));
