import React from 'react';
import BackgroundContainer from './layoutComponents/BackgroundContainer';
import MigrationHeading from './MigrationHeading';
import MigrationBody from './migrationBody/MigrationBody';
import FAQSection from './migrationBody/FAQSection';

const MigrationPage: React.FC = () => (
  <BackgroundContainer>
    <MigrationHeading />
    <MigrationBody />
    <FAQSection />
  </BackgroundContainer>
);

export default MigrationPage;
