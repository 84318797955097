import React, { useState, useContext } from 'react';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import { useHistory } from 'react-router-dom';
import { ExtensionInstalledContext } from '../contexts/ExtensionInstallState';
import background from '../../img/background@1x.png';
import Footer from '../layout/Footer';
import HeaderSafeSearch from './HeaderSafeSearch';
import CloseButtonImage from '../../img/close-button.png';
import CloseModal from './CloseModal';
import { useMixpanel } from '../hooks';

const BackgroundContainer = styled('div')`
  position: relative;
  width: 100%;
  min-width: 375px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BackgroundImage = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url(${background});
  background-size: cover;
  @media (forced-colors: active) {
    background: linear-gradient( rgba(0,0,0,0.7), rgba(0, 0, 0, 0.7) ),url(${background}) no-repeat center center fixed;
  }
`;

const StyledCloseButton = styled('button')`
  display: inline-block;
  border: none;
  background: none;
  cursor: pointer;
  float:right;
  padding: 0;
  font-size: 0;
  width: 20px;
  height: 20px;
`;

const ButtonBox = styled(Box)`
@media only screen and (max-width: 992px) {
  background: #FFFFFF;
  border-bottom: solid 1px #E6EAEC;
 }
`;

const BackgroundSafeSearch: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const installedState = useContext(ExtensionInstalledContext);
  const history = useHistory();
  const mixpanel = useMixpanel();

  const clickedXButton = (): void => {
    mixpanel.trackXOptionClicked();
    if (installedState.safesearch) {
      history.push('/');
    } else {
      mixpanel.trackXDialogDisplayed();
      setShowModal(true);
    }
  };

  const closeClick = (value: boolean): void => {
    mixpanel.trackXDialogYesClicked();
    setShowModal(value);
  };

  return (
    <BackgroundContainer>
      <BackgroundImage />
      <Box width= "100%" display="flex">
        <HeaderSafeSearch />
        <ButtonBox width="20%" height="60%" padding='30px'>
          <StyledCloseButton onClick = { () => { clickedXButton(); }}>
            <img src={CloseButtonImage} alt="Close" />
          </StyledCloseButton>
        </ButtonBox>
      </Box>
      <Box px="l" flex="1 1 auto" position="relative">
        {children}
      </Box>
      <CloseModal show={ showModal } closeClick={ closeClick } />
      <Footer />
    </BackgroundContainer>
  );
};

export default BackgroundSafeSearch;
