import React from 'react';
import PropTypes from 'prop-types';
import ABTestDecider from 'pwm-components/objects/ABTestDecider';
import ongoingExperiments from '../ongoingExperiments';
import { useMixpanel } from './hooks';

const ABTest = (props) => {
  const mixpanel = useMixpanel();
  const getStoredDiceRoll = () => (
    localStorage.getItem('DiceRoll')
  );

  const rollDice = () => {
    const diceNumber = Math.random();
    localStorage.setItem('DiceRoll', diceNumber);
    return diceNumber;
  };

  const getDiceRoll = () => {
    const diceRoll = getStoredDiceRoll();
    return diceRoll || rollDice();
  };

  return (
    <ABTestDecider
      diceRoll={getDiceRoll()}
      experiments={ongoingExperiments}
      onExperimentsCalculated={(AvailableABTests) => {
        mixpanel.register(AvailableABTests);
      }}>
      {props.children}
    </ABTestDecider>
  );
};

ABTest.propTypes = {
  children: PropTypes.arrayOf[PropTypes.object] || PropTypes.object,
};

export default ABTest;
