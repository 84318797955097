import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ExpandLessIcon,
  FAQContainer,
  FAQHeading,
  FAQInfo,
  FAQText,
} from './FAQ.styles';

const FAQ: React.FC<{
  headingID: string;
  headingDefaultMessage: string;
  infoID: string;
  infoDefaultMessage: string;
  subInfoID?: string;
  subInfoDefaultMessage?: string;
}> = ({
  headingID,
  headingDefaultMessage,
  infoID,
  infoDefaultMessage,
  subInfoID,
  subInfoDefaultMessage,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FAQContainer onClick={() => setIsOpen(!isOpen)}>
      <ExpandLessIcon direction={isOpen ? '180deg' : '0deg'} />
      <FAQText>
        <FAQHeading>
          <FormattedMessage
            id={headingID}
            defaultMessage={headingDefaultMessage}
          />
        </FAQHeading>
        {isOpen === true && (
          <>
            <FAQInfo>
              <FormattedMessage
                id={infoID}
                defaultMessage={infoDefaultMessage}
              />
            </FAQInfo>
            {subInfoID && (
              <FAQInfo>
                <FormattedMessage
                  id={subInfoID}
                  defaultMessage={subInfoDefaultMessage}
                />
              </FAQInfo>
            )}
          </>
        )}
      </FAQText>
    </FAQContainer>
  );
};

export default FAQ;
