import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';
import FontSizes from '../../../baseComponents/variables/fontSizes';
import { FontFamily, FontWeight } from '../../../baseComponents/variables/font';
import LineHeightEnum from '../../../baseComponents/variables/lineHeight';
import expandLess from '../../../img/expandLess.svg';

export const FAQContainer = styled('button')`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${colors.white};
  padding: 26px;
  border: none;
  border-bottom: 1px solid #DDDBD9;
  text-align: left;
`;

export const FAQText = styled('div')`
  margin-left: 26px;
`;

export const FAQHeading = styled('div')`
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.littleThree};
  line-height: ${LineHeightEnum.greatPrimer};
  font-weight: ${FontWeight.bold};
  color: ${colors.shark};
`;

export const FAQInfo = styled('div')`
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.pica};
  line-height: ${LineHeightEnum.english};
  font-weight: ${FontWeight.normal};
  color: ${colors.shark};
  opacity: 0.75;
  margin-top: 24px;
`;

export type ExpandLessIconProps = {
  direction: string;
};

export const ExpandLessIcon = styled('img')<ExpandLessIconProps>`
  content: url(${expandLess});
  transform: rotate(${props => props.direction});
  transition: 0.5s;
`;
