import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Card from 'pwm-components/components/Card';
import Paragraph from 'pwm-components/components/Paragraph';
import useResponsiveWidth from 'pwm-components/hooks/useResponsiveWidth';
import homepage1x from '../../img/safe-search-homepage1x.png';
import homepagecontrast from '../../img/Homepage_4.svg';
import lineVector from '../../img/line-vector.png';
import safesearch1x from '../../img/Safesearch1x.png';
import safesearchcontrast from '../../img/Safesearch.svg';
import added1x from '../../img/main/Group@1x.png';
import added2x from '../../img/main/Group@2x.png';
import added3x from '../../img/main/Group@3x.png';
import errorIcon from '../../img/errorIcon.svg';
import BackgroundSafeSearch from './BackgroundSafeSearch';
import AddToButton from '../AddToButton';
import { LocaleContext } from '../../locale/LocaleContainer';
import { ExtensionInstalledContext } from '../contexts/ExtensionInstallState';
import { useBrowserConfig, useMixpanel } from '../hooks';


const InfoCard = styled(Card)<{ screenWrap?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: transparent;
  box-shadow: none;
  border-radius: ${({ theme: { space } }) => space.xxs};
  margin: ${({ theme: { space } }) => space.xs};
  padding: 48px;
`;

const Line = styled(Box)<{ screenWrap?: boolean }>`
  margin-top: 50px;
  margin-right: 40px;
  margin-left: 40px;
  @media only screen and (max-width: 1159px) {
    display: none;
   }
`;

const BoxImg = styled(Box)<{ screenWrap?: boolean }>`
margin-top: 0px;
margin-bottom: 0px;
text-align: center;
`;

const Link = styled('a')<{ screenWrap?: boolean }>`
color: black;
text-decoration: underline;
`;

const ParagraphDescription = styled(Paragraph)<{ screenWrap?: boolean }>`
margin-top: 15px;
width: 60%;
font-size: 12px;
color: black;
line-height: 18px;
`;

const ParagraphInfo = styled(Paragraph)<{ screenWrap?: boolean }>`
line-height: 20px;
font-size: 14px;
color: black;
`;

const ParagraphError = styled(Box)<{ screenWrap?: boolean }>`
color: #E11F27;
font-size: 14px;
display: flex;
text-align: center;
`;

const BoxError = styled(Box)<{ screenWrap?: boolean }>`
margin-top: 0px;
margin-bottom: 0px;
margin-right: 3px;
text-align: center;
`;

const ParagraphLarge = styled(Paragraph)<{ screenWrap?: boolean }>`
font-size: 32px;
line-height: 43px;
color: #333333;
`;

const ParagraphMedium = styled(Paragraph)<{ screenWrap?: boolean }>`
font-size: 18px;
line-height: 24px;
color: #1B2428;
`;

const ParagraphSmall = styled(Paragraph)<{ screenWrap?: boolean }>`
font-size: 12px;
color: black;
line-height: 20px;
`;

const ImgSafeSearch = styled(Box)<{ screenWrap?: boolean }>`
width: 32px;
height: 32px;
background-image: url(${safesearch1x});
background-size: 33px;
@media (forced-colors: active) {
  background-image: url(${safesearchcontrast}) !important;
}
`;

const ImgHome = styled(Box)<{ screenWrap?: boolean }>`
width: 40px;
height: 32px;
background-image: url(${homepage1x});
@media (forced-colors: active) {
  background-image: url(${homepagecontrast}) !important;
}
`;

const getLanguage = (currLocale: string): string => {
  let lang = currLocale.toLowerCase();

  if (lang === 'nl-nl' || lang === 'tr-tr' || lang === 'ja-jp') {
    const [langSplit] = lang.split('-');
    lang = langSplit;
  }

  return lang;
};

const getLicenseAndServicesAgreementLink = (currLocale: string): string => {
  const lang = getLanguage(currLocale);

  return `https://www.avira.com/${lang}/legal-terms`;
};

const getPrivacyPolicyLink = (currLocale: string): string => {
  const lang = getLanguage(currLocale);

  if (lang === 'ja') {
    return 'https://www.avira.com/ja/privacy-policy/homepage?co_type=F2F&contact_id=56368009&message_category=W2P&message_content=B2C_W2P_SUNSETTING_REC_50_days&x-a-content=B2C_W2P_SUNSETTING_REC_50_days&x-a-type=W2P';
  }
  return `https://www.avira.com/${lang}/privacy-policy/homepage`;
};

const getProductPrivacyNoticeLink = (currLocale: string): string => {
  const lang = getLanguage(currLocale);

  return `https://www.avira.com/${lang}/avira-safesearch-privacy-policy`;
};

// eslint-disable-next-line complexity
const GetSafeSearch: React.FC = () => {
  const { locale: currLocale } = useContext(LocaleContext);
  const screenWrap = useResponsiveWidth() === 'mobile';

  const installedState = useContext(ExtensionInstalledContext);
  const installedSafeSearch = installedState.safesearch;


  const licenseAndServicesAgreementLink = getLicenseAndServicesAgreementLink(currLocale);
  const privacyPolicyLink = getPrivacyPolicyLink(currLocale);
  const productPrivacyNoticeLink = getProductPrivacyNoticeLink(currLocale);
  const { error } = useMixpanel();
  const { storeURLs } = useBrowserConfig();

  useEffect(() => {
    document.title = 'Avira - Safe Search';
  }, []);

  // sending a close event to the extension so that it in turn will close the store page
  useEffect(() => {
    if (installedSafeSearch) {
      window.postMessage({
        action: 'close',
        url: storeURLs.safesearch,
      }, document.URL);
    }
  }, [installedSafeSearch, storeURLs.safesearch]);

  return (
    <BackgroundSafeSearch>
      <Box
        pb="xxxl"
        px="xxl"
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <ParagraphLarge mb="s" size="h1" textAlign="center">
          {installedSafeSearch && (
            <FormattedMessage
              id="getSafeSearch.install.succes.title"
              defaultMessage="Thank you, extension has been successfully added"
            />
          )}
          {!installedSafeSearch && (
            <FormattedMessage
              id="getSafeSearch.title"
              defaultMessage="Secure your searches with Avira Safe Search"
            />
          )}
        </ParagraphLarge>
        <Box position="relative" display="flex" flexWrap="wrap" justifyContent="center">
          <InfoCard width={screenWrap ? '100%' : '475px'}>
            <ImgHome />
            <ParagraphMedium my="m" textAlign="center">
              <strong>
                <FormattedMessage
                  id="getSafeSearch.homePage"
                  defaultMessage="Avira Home Page"
                />
              </strong>
            </ParagraphMedium>
            <ParagraphInfo textAlign="center">
              <FormattedMessage
                id="getSafeSearch.homePage.description"
                defaultMessage="Update your Chrome Home page and New Tab page to safesearch.avira.com and we'll let you know if a site is safe."
              />
            </ParagraphInfo>
          </InfoCard>
          <Line>
            <img
              alt="Home Page"
              width="1"
              height="180"
              src={lineVector}
            />
          </Line>
          <InfoCard width={screenWrap ? '100%' : '475px'}>
            <ImgSafeSearch />
            <ParagraphMedium my="m" textAlign="center">
              <strong>
                <FormattedMessage
                  id="getSafeSearch.safeSearch"
                  defaultMessage="Avira Safe Search"
                />
              </strong>
            </ParagraphMedium>
            <ParagraphInfo textAlign="center">
              <FormattedMessage
                id="getSafeSearch.safeSearch.description"
                defaultMessage="Avira Safe Search lets you know if the site you're searching for is risky, by marking any unsafe search results. Update your Chrome address bar to safesearch.avira.com for safer results."
              />
            </ParagraphInfo>
          </InfoCard>
        </Box>
        <ParagraphSmall textAlign="center" size="small">
          <FormattedMessage
            id="getSafeSearch.safeSearch.description1"
            defaultMessage="By installing Avira Safe Search extension, you're agreeing to the {licenseAndServicesAgreement}, {productPrivacyNotice} and the {privacyPolicy}."
            values={{
              licenseAndServicesAgreement: <Link href={licenseAndServicesAgreementLink} target="_blank">
                <FormattedMessage
                  id="getSafeSearch.safeSearch.licenseAndServicesAgreement"
                  defaultMessage="License And Services Agreement"
                />
              </Link>,
              productPrivacyNotice: <Link href={productPrivacyNoticeLink} target="_blank">
                <FormattedMessage
                  id="getSafeSearch.safeSearch.productPrivacyNotice"
                  defaultMessage="Avira Safe Search Product Privacy Notice"
                />
              </Link>,
              privacyPolicy: <Link href={privacyPolicyLink} target="_blank">
                <FormattedMessage
                  id="getSafeSearch.safeSearch.privacyPolicy"
                  defaultMessage="Avira Privacy Policy"
                />
              </Link>,
            }}
          />
        </ParagraphSmall>
        <ParagraphSmall textAlign="center" size="small">
          <FormattedMessage
            id="getSafeSearch.safeSearch.description2"
            defaultMessage="Clicking on Add To Chrome will update your default address bar search, home page, and New Tab page to {website}."
            values={{
              website: 'safesearch.avira.com',
            }}
          />
        </ParagraphSmall>
        <ParagraphDescription textAlign="center" size="small">
          <FormattedMessage
            id="getSafeSearch.safeSearch.description3"
            defaultMessage="Avira is a security company, not a search engine. Search results are provided by Ask Media Group. By conducting a search, you direct Avira to disclose your personal information to Ask Media Group."
          />
        </ParagraphDescription>
        {installedSafeSearch && (
          <>
            <Box style={{ marginTop: '36px' }}>
              <Paragraph m="xs" size="h4" variant="success">
                <strong>
                  <FormattedMessage
                    id="extension.extensionInstalled"
                    defaultMessage="ADDED"
                  />
                </strong>
              </Paragraph>
              <BoxImg my="xl">
                <img
                  alt="Added"
                  src={added1x}
                  srcSet={`${added1x} 1x, ${added2x} 2x, ${added3x} 3x`}
                />
              </BoxImg>
            </Box>
          </>
        )}
        {!installedSafeSearch && (
          <>
            <Box mt="xxl" mb="xxl" style={{ alignSelf: 'center', minWidth: '300px' }}>
              <AddToButton type="safesearch" />
            </Box>
          </>
        )}
        {!installedSafeSearch && error && (
          <>
            <ParagraphError>
              <BoxError my="xl">
                <img
                  alt="Error"
                  src={errorIcon}
                />
              </BoxError>
              <FormattedMessage
                id="getSafeSearch.install.error"
                defaultMessage="Avira Safe Search has failed to install. Please try again."
              />
            </ParagraphError>

          </>
        )}
      </Box>
    </BackgroundSafeSearch>

  );
};

export default GetSafeSearch;
