
import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';
import FontSizes from '../../../baseComponents/variables/fontSizes';
import { FontFamily, FontWeight } from '../../../baseComponents/variables/font';
import LineHeightEnum from '../../../baseComponents/variables/lineHeight';

export const StepTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const StepTextHeading = styled('div')`
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.pica};
  line-height: ${LineHeightEnum.english};
  font-weight: ${FontWeight.bold};
  color: ${colors.white};
`;

export const StepTextInfo = styled('div')`
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.greatPrimer};
  line-height: ${LineHeightEnum.doubleColumbian};
  font-weight: ${FontWeight.semibold};
  color: ${colors.white};
  margin-top: 8px;
  strong {
    font-weight: ${FontWeight.extrabold};
  }
`;
