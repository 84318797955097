export enum FontWeight {
  light = '200',
  normal = '400',
  medium = '500',
  semibold = '600',
  bold = '700',
  extrabold = '800'
}

export enum FontFamily {
  Kievit = 'Kievit',
  OpenSans = 'Open Sans',
  Inter = 'Inter',
  Arial = 'Arial',
  PTMono = 'PT Mono',
}
