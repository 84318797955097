import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';
import FontSizes from '../../baseComponents/variables/fontSizes';
import { FontFamily, FontWeight } from '../../baseComponents/variables/font';
import LineHeightEnum from '../../baseComponents/variables/lineHeight';
import installNow from '../../img/installNow.svg';

export const Heading = styled('div')`
  text-align: center;
  padding-top: 44px;
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.doubleGreatPrimer};
  line-height: ${LineHeightEnum.doubleEnglish};
  font-weight: ${FontWeight.bold};
  letter-spacing: -0.2px;
  color: ${colors.shark};
`;

export const SubHeading = styled('div')`
  text-align: center;
  padding-top: 16px;
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.doublePica};
  line-height: ${LineHeightEnum.doublePica};
  font-weight: ${FontWeight.bold};
  letter-spacing: -0.2px;
  color: ${colors.shark};
  opacity: 0.75;
`;

export const HeadingInfoContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

export const HeadingInfo = styled('div')`
  text-align: center;
  width: 700px;
  padding-top: 16px;
  font-family: ${FontFamily.Kievit};
  font-size: ${FontSizes.english};
  line-height: ${LineHeightEnum.paragon};
  font-weight: ${FontWeight.medium};
  color: ${colors.shark};
`;

export const StatusContainer = styled('div')`
  height: 150px;
`;

export const StatusTextHeading = styled('div')`
  text-align: center;
  margin-top: 16px;
  padding-top: 12px;
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.doublePica};
  line-height: ${LineHeightEnum.doublePica};
  font-weight: ${FontWeight.bold};
  letter-spacing: -0.2px;
  color: ${colors.navyblue};
`;

export const InstallNowImage = styled('img')`
  margin-right: 16px;
  content: url(${installNow});
`;

export const InstallNowButton = styled('button')`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 64px;
  background-color: ${colors.navyblue};
  border: none;
  border-radius: 2px;
  cursor: pointer;
`;

export const InstallNowButtonView = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 250px;
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.english};
  line-height: ${LineHeightEnum.paragon};
  font-weight: ${FontWeight.bold};
  letter-spacing: -0.2px;
  color: ${colors.white};
`;
export const InstallNowButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
`;
