import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Card from 'pwm-components/components/Card';
import Paragraph from 'pwm-components/components/Paragraph';

import added1x from '../../img/main/Group@1x.png';
import added2x from '../../img/main/Group@2x.png';
import added3x from '../../img/main/Group@3x.png';

import CHIPseal1x from '../../img/CHIPseal@1x.png';
import CHIPseal2x from '../../img/CHIPseal@2x.png';
import CHIPseal3x from '../../img/CHIPseal@3x.png';

import AddToButton from '../AddToButton';
import { ExtensionKey } from '../ExtensionTypes';
import { useBrowserConfig } from '../hooks';
import { ExtensionInstalledContext } from '../contexts/ExtensionInstallState';

const FullHeightCard = styled(Card)`
  height: 100%;
`;

export type Props = {
  type: ExtensionKey;
  icon: JSX.Element;
  title: JSX.Element | string;
  description: JSX.Element | string;
  seal?: boolean;
};

const ExtensionCard: React.FC<Props> = ({
  type,
  icon,
  title,
  description,
  seal = false,
}) => {
  const installed = useContext(ExtensionInstalledContext)[type];
  const { storeURLs } = useBrowserConfig();

  if (!storeURLs[type]) {
    return <></>;
  }

  return (
    <FullHeightCard m="xs" minHeight="302px">
      <Box
        p="l"
        display="flex"
        height="100%"
        width="294px"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
      >
        {icon}
        <Paragraph p="s" size="h4">
          <strong>{title}</strong>
        </Paragraph>
        {installed && (
          <>
            <Paragraph m="xs" size="h4" variant="success">
              <strong>
                <FormattedMessage
                  id="extension.extensionInstalled"
                  defaultMessage="ADDED"
                />
              </strong>
            </Paragraph>
            <Box my="xl">
              <img
                alt="Added"
                src={added1x}
                srcSet={`${added1x} 1x, ${added2x} 2x, ${added3x} 3x`}
              />
            </Box>
          </>
        )}
        {!installed && (
          <>
            <Paragraph mb="xl" textAlign="center">
              {description}
            </Paragraph>
            {seal && (
              <Box width="100%" pb="xl">
                <img
                  width="100%"
                  alt="CHIPSeal"
                  src={CHIPseal1x}
                  srcSet={`${CHIPseal1x} 1x, ${CHIPseal2x} 2x, ${CHIPseal3x} 3x`}
                />
              </Box>
            )}
            <Box width="100%" style={{ alignSelf: 'flex-end' }}>
              <AddToButton type={type} />
            </Box>
          </>
        )}
      </Box>
    </FullHeightCard>
  );
};

export default ExtensionCard;
