import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';
import TextButton from 'pwm-components/components/TextButton';
import { textColor, TextColorProps } from 'pwm-components/variables/textColors';
import { textAlign, TextAlignProps } from 'styled-system';
import Text from 'pwm-components/components/Text';
import sizes from 'pwm-components/variables/sizes';

export type Props = TextColorProps & TextAlignProps & {
  spacing?: 'small' | 'medium';
};

export const DropdownMenuItem = styled('li')<Props>`
  cursor: pointer;
  ${textColor}
  ${textAlign}

  &:first-child, &:last-child {
    overflow: hidden;
  }

  &:not(:last-child) {
    border-bottom: solid 1px ${colors.alabaster};
  }

  & > ${TextButton}, & > ${Text}, & > a, & > div {
    ${textAlign}
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    user-select: none;
    padding: ${({ spacing }) => (spacing === 'medium' ? `${sizes.xs} ${sizes.s}` : `${sizes.xxs} ${sizes.s}`)};
  }

  &:hover {
    background-color: ${colors.gainsboro};
  }
`;

DropdownMenuItem.defaultProps = {
  variant: 'inherit',
  spacing: 'medium',
  textAlign: 'left',
};
