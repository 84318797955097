import React from 'react';
import styled from 'styled-components';
import {
  Banner,
  PromotionsContainer,
  MigrationBodyContainer,
} from './MigrationBody.styles';
import extension from '../../../img/extension.svg';
import cursor from '../../../img/cursor.svg';
import store from '../../../img/store.svg';
import rightArrow from '../../../img/rightArrow.svg';
import sale from '../../../img/sale.svg';
import wallet from '../../../img/wallet.svg';
import globe from '../../../img/globe.svg';
import bottomArrow from '../../../img/bottomArrow.svg';
import Promotion from './Promotion';
import BannerStep from './BannerStep';
import Info from './Info';

const RightArrow = styled('img').attrs(() => ({ alt: '->' }))`
    margin-left: 48px;
    margin-right: 48px;
    content: url(${rightArrow});
`;

const BottomArrow = styled('img').attrs(() => ({ alt: 'v' }))`
  height: 50px;
  width: 50px;
  content: url(${bottomArrow});
`;

const BottomArrowButton = styled('button')`
  position: relative;
  display: inline-block;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  top: -25px;
  height: 50px;
  width: 50px;
`;

const MigrationBody: React.FC = () => {
  const promotionItems = [
    {
      headingID: 'migration.promotion.step.one.heading',
      headingDefaultMessage: 'Save more',
      infoID: 'migration.promotion.step.one.info',
      infoDefaultMessage: 'Enjoy savings while shopping online',
      imageURL: sale,
    },
    {
      headingID: 'migration.promotion.step.two.heading',
      headingDefaultMessage: '18% off',
      infoID: 'migration.promotion.step.two.info',
      infoDefaultMessage: 'the cart on average',
      imageURL: wallet,
    },
    {
      headingID: 'migration.promotion.step.three.heading',
      headingDefaultMessage: 'Over 5000',
      infoID: 'migration.promotion.step.three.info',
      infoDefaultMessage: 'brand partnerships',
      imageURL: globe,
    },
  ];

  const bannerItems = [
    {
      headingID: 'migration.banner.step.one.heading',
      headingDefaultMessage: 'STEP 1',
      infoID: 'migration.banner.step.one.info',
      infoDefaultMessage: 'Click <strong>Install now</strong>',
      imageURL: cursor,
    },
    {
      headingID: 'migration.banner.step.two.heading',
      headingDefaultMessage: 'STEP 2',
      infoID: 'migration.banner.step.two.info',
      infoDefaultMessage: 'Add extension from the Web store',
      imageURL: extension,
    },
    {
      headingID: 'migration.banner.step.three.heading',
      headingDefaultMessage: 'STEP 3',
      infoID: 'migration.banner.step.three.info',
      infoDefaultMessage: 'Shop Safely',
      imageURL: store,
    },
  ];
  return (
    <MigrationBodyContainer>
      <Banner>
        {bannerItems.map((item, index) => (
          <React.Fragment key={index}>
            <BannerStep
              imageURL={item.imageURL}
              headingID={item.headingID}
              headingDefaultMessage={item.headingDefaultMessage}
              infoID={item.infoID}
              infoDefaultMessage={item.infoDefaultMessage}
            />
            {index < bannerItems.length - 1 && <RightArrow />}
          </React.Fragment>
        ))}
      </Banner>
      <PromotionsContainer>
        {promotionItems.map((item, index) => (
          <Promotion
            key={index}
            imageURL={item.imageURL}
            headingID={item.headingID}
            headingDefaultMessage={item.headingDefaultMessage}
            infoID={item.infoID}
            infoDefaultMessage={item.infoDefaultMessage}
          />
        ))}
      </PromotionsContainer>
      <BottomArrowButton
        onClick={() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        }}
      >
        <BottomArrow />
      </BottomArrowButton>
      <Info />
    </MigrationBodyContainer>
  );
};

export default MigrationBody;
