import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  StepTextContainer,
  StepTextHeading,
  StepTextInfo,
} from './BannerStep.styles';

const BannerStep: React.FC<{
  imageURL: string;
  headingID: string;
  headingDefaultMessage: string;
  infoID: string;
  infoDefaultMessage: string;
}> = ({
  imageURL,
  headingID,
  headingDefaultMessage,
  infoID,
  infoDefaultMessage,
}) => (
  <>
    <img alt="Step" src={imageURL} />
    <StepTextContainer>
      <StepTextHeading>
        <FormattedMessage
          id={headingID}
          defaultMessage={headingDefaultMessage}
        />
      </StepTextHeading>
      <StepTextInfo>
        <FormattedMessage
          id={infoID}
          defaultMessage={infoDefaultMessage}
          values={{
            strong: (word: ReactNode) => <strong>{word}</strong>,
          }}
        />
      </StepTextInfo>
    </StepTextContainer>
  </>
);

export default BannerStep;
