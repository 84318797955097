import React from 'react';
import {
  browserName,
} from 'react-device-detect';
import config, { Extension } from '../../../config';
import { ExtensionKey } from '../../ExtensionTypes';

type StoreUrls = { [P in ExtensionKey]?: string };

const SubSourceTracking = '?subSource=Extension Onboarding';

export type Props = {
  storeURLs: StoreUrls;
  browser: string;
  supported: boolean;
  extensions: Record<string, Extension>;
};

export const BrowserContext = React.createContext<Props>({
  storeURLs: {},
  browser: '',
  supported: false,
  extensions: {},
});

const BrowserConfig: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const browserConfig = config.browserConfigs[browserName.toLocaleLowerCase()];
  const supported = !!browserConfig;
  let storeURLs = {};
  let extensions: Record<string, Extension> = {};

  if (supported) {
    const { storeLink } = browserConfig;
    ({ extensions } = browserConfig);
    storeURLs = Object.keys(extensions).reduce((urls, extensionType) => {
      let url;
      const extension = extensions[extensionType];
      if (browserName === 'Chrome') {
        const storeName = extension.name;
        const storeId = extension.id;
        url = `${storeLink}${storeName}/${storeId}${SubSourceTracking}`;
      } else {
        const storeId = extension.id;
        url = `${storeLink}${storeId}${SubSourceTracking}`;
      }
      return { ...urls, [extensionType]: url };
    }, {});
  }

  return (
    <BrowserContext.Provider value={{
      storeURLs, browser: browserName, supported, extensions,
    }}>
      {children}
    </BrowserContext.Provider>
  );
};

export default BrowserConfig;
