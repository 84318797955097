/* eslint-disable @typescript-eslint/no-array-constructor */
import React, { useState, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'pwm-components/styled';
import useResponsiveWidth from 'pwm-components/hooks/useResponsiveWidth';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Text from 'pwm-components/components/Text';
import Button from 'pwm-components/components/Button';
import Select from 'pwm-components/components/Select';
import Input from 'pwm-components/components/Input';
import { useMixpanel } from '../hooks';
import { ExtensionKey } from '../ExtensionTypes';
import Background from '../Background';
import UninstallMessages from './UninstallMessages';

const UppercaseButton = styled(Button)`
  text-transform: uppercase;
`;

export type Props = {
  extension: ExtensionKey;
}

// eslint-disable-next-line complexity
const Uninstall: React.FC<Props> = ({ extension }) => {
  const intl = useIntl();
  const mixpanel = useMixpanel();
  const screen = useResponsiveWidth();
  let width = 0;
  switch (screen) {
    case 'mobile':
      width = 720;
      break;
    case 'tablet':
      width = 960;
      break;
    default:
      width = 1180;
      break;
  }

  let sse = false;
  let pwm = false;
  let abs = false;

  if (extension === 'abs') {
    abs = true;
  } else if (extension === 'sse') {
    sse = true;
  } else {
    pwm = true;
  }
  const [options, setOptions] = useState<{ id: string; label: string }[]>([]);
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [submitted, setSubmitted] = useState(false);


  useEffect(() => {
    const optionsList = new Array();
    const uninstallFormIds = ['uninstall.pleaseSelect', 'uninstall.typeYourAnswer'];
    const pwmIds = ['uninstall.whyUninstall.tooExpensive', 'uninstall.whyUninstall.forgotMasterPassword'];

    Object.keys(UninstallMessages).forEach((key) => {
      if (!uninstallFormIds.includes(key)) {
        if (extension === 'pwm' || !pwmIds.includes(key)) {
          optionsList.push({ id: key, label: intl.formatMessage(Object(UninstallMessages)[key]) });
        }
      }
    });

    setOptions(optionsList);
  }, [extension, intl]);

  const onSubmit = useCallback(async () => {
    setSubmitted(true);
    mixpanel.trackExtensionUninstalled(extension, reason);
  }, [mixpanel, reason, extension]);

  return (
    <Background>
      <Box m="a" display="flex" flexDirection="column" height="100%" maxWidth={width} flex="1 1 auto">
        {
          submitted ? (
            <Paragraph size="h1" mb="l">
              <strong>
                <FormattedMessage
                  id="uninstall.thankYou"
                  defaultMessage="Thank you! Your feedback helps us improve."
                />
              </strong>
            </Paragraph>
          ) : (
            <>
              <Box flex="1 1 auto">
                <Paragraph size="h1" mb="l">
                  <strong>
                    { pwm && (
                      <FormattedMessage
                        id="uninstall.sadToSeeYouGoPWM"
                        defaultMessage="It's sad to see you go. Why are you uninstalling Avira Password Manager?"
                      />
                    )
                    }
                    { abs && (
                      <FormattedMessage
                        id="uninstall.sadToSeeYouGoABS"
                        defaultMessage="It's sad to see you go. Why are you uninstalling Avira Browser Safety?"
                      />
                    )
                    }
                    { sse && (
                      <FormattedMessage
                        id="uninstall.sadToSeeYouGoSSE"
                        defaultMessage="It's sad to see you go. Why are you uninstalling Avira Safe Shopping?"
                      />
                    )
                    }
                  </strong>
                </Paragraph>
                <Paragraph size="normal" mb="xs">
                  <FormattedMessage
                    id="uninstall.bestSolution"
                    defaultMessage="We want to create the best possible solution to address your privacy and passwords problems."
                  />
                </Paragraph>
                <Paragraph size="normal" mb="l">
                  <FormattedMessage
                    id="uninstall.tellUs"
                    defaultMessage="Please take a few moments to tell us why you uninstalled Avira."
                  />
                </Paragraph>
                <Paragraph size="small" mb="xs">
                  <FormattedMessage
                    id="uninstall.whyUninstall"
                    defaultMessage="Why did you uninstall Avira?"
                  />
                  <Text variant="alert" size="large"> *</Text>
                </Paragraph>
                <Select
                  options={options}
                  onChange={e => setReason(e)}
                  placeholder={intl.formatMessage({
                    id: 'uninstall.pleaseSelect',
                    defaultMessage: 'Please select an option',
                  })}
                />
                <Paragraph size="small" mt="l" mb="xs">
                  <FormattedMessage
                    id="uninstall.anythingElse"
                    defaultMessage="Is there anything else you want to tell us?"
                  />
                </Paragraph>
                <Box height="150px">
                  <Input
                    kind="textarea"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    placeholder={intl.formatMessage({
                      id: 'uninstall.typeYourAnswer',
                      defaultMessage: 'Type your answer here',
                    })}
                    crossOrigin={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                </Box>
              </Box>
              <Box my="xxxl" width="250px">
                <UppercaseButton
                  fullWidth
                  variant="primary"
                  disabled={!reason || reason.length === 0}
                  onClick={onSubmit}
                  placeholder=""
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <FormattedMessage
                    id="uninstall.submit"
                    defaultMessage="Submit"
                  />
                </UppercaseButton>
              </Box>
            </>
          )
        }
      </Box>
    </Background>
  );
};

export default Uninstall;
