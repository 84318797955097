
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/polyfill-locales';

import { IntlProvider } from 'react-intl';
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import english from './strings/en.json';
import german from './strings/de.json';
import spanish from './strings/es.json';
import french from './strings/fr.json';
import italian from './strings/it.json';
import japanese from './strings/ja-JP.json';
import dutch from './strings/nl-NL.json';
import portuguese from './strings/pt-BR.json';
import russian from './strings/ru.json';
import turkish from './strings/tr-TR.json';
import chineseSimplified from './strings/zh-CN.json';
import chineseTraditional from './strings/zh-TW.json';

const messages = {
  en: english,
  de: german,
  es: spanish,
  fr: french,
  it: italian,
  'ja-JP': japanese,
  'nl-NL': dutch,
  'pt-BR': portuguese,
  ru: russian,
  'tr-TR': turkish,
  'zh-CN': chineseSimplified,
  'zh-TW': chineseTraditional,

};

export const languages = {
  en: {
    locale: 'en',
    language: 'English',
  },
  de: {
    locale: 'de',
    language: 'Deutsch',
  },
  es: {
    locale: 'es',
    language: 'Español',
  },
  fr: {
    locale: 'fr',
    language: 'Français',
  },
  it: {
    locale: 'it',
    language: 'Italiano',
  },
  'ja-JP': {
    locale: 'ja-JP',
    language: '日本語',
  },
  'nl-NL': {
    locale: 'nl-NL',
    language: 'Nederlands',
  },
  'pt-BR': {
    locale: 'pt-BR',
    language: 'Português',
  },
  ru: {
    locale: 'ru',
    language: 'Pу́сский',
  },
  'tr-TR': {
    locale: 'tr-TR',
    language: 'Türkçe',
  },
  'zh-CN': {
    locale: 'zh-CN',
    language: '简体中文',
  },
  'zh-TW': {
    locale: 'zh-TW',
    language: '繁體中文',
  },
};

const detectLanguage = (twoLetterLanguageCode) => {
  const DEFAULT_LOCALE = 'en';
  if (twoLetterLanguageCode === 'pt') return languages[DEFAULT_LOCALE];

  const localeList = Object.keys(languages);
  for (let i = 0; i < localeList.length; i += 1) {
    if (localeList[i].indexOf(twoLetterLanguageCode) === 0) {
      return languages[localeList[i]];
    }
  }
  return languages[DEFAULT_LOCALE];
};

export const getLocatizationSettings = (lang) => {
  const short = lang.slice(0, 2).toLowerCase();
  const long = `${short}-${lang.slice(3, 5).toUpperCase()}`;

  if (lang.length === 2 && languages[short] != null) {
    return languages[short];
  } if (languages[long] != null) {
    return languages[long];
  } if (languages[short] != null) {
    return languages[short];
  }
  return detectLanguage(short);
};

const defaultLanguageSetting = getLocatizationSettings(
  navigator.language || navigator.userLanguage,
);

export const LocaleContext = React.createContext({
  locale: languages.en.locale,
  // eslint-disable-next-line no-unused-vars
  setLocale: (_) => {},
});

const LocaleContainer = (props) => {
  const [locale, setLocale] = useState(defaultLanguageSetting.locale);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <LocaleContext.Provider value={{
        locale,
        setLocale,
      }}>
        {props.children}
      </LocaleContext.Provider>
    </IntlProvider>
  );
};

LocaleContainer.propTypes = {
  children: PropTypes.object,
};

export default LocaleContainer;
