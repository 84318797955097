import styled from 'pwm-components/styled';
import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Background = styled('div')`
  position: relative;
  width: 100%;
  min-width: 375px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BackgroundContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Background>
    <Header />
    {children}
    <Footer />
  </Background>
);

export default BackgroundContainer;
