import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FAQsContainer, FAQSectionContainer, FAQSectionHeading } from './FAQSection.style';
import FAQ from './FAQ';

const FAQSection: React.FC = () => {
  const FAQItems = [
    {
      headingID: 'migrationPage.faq.item1.heading',
      headingDefaultMessage: 'What is a promo code?',
      infoID: 'migrationPage.faq.item1.info',
      infoDefaultMessage:
        'A promo code is like a digital coupon that you can use to get a discount or special offer when you buy something online. Avira Safe Shopping can help you find promo codes for over 5,000 brands and it will automatically add the promo code for you at checkout for instant savings.',
    },
    {
      headingID: 'migrationPage.faq.item2.heading',
      headingDefaultMessage: 'How do I use the extension?',
      infoID: 'migrationPage.faq.item2.info',
      infoDefaultMessage:
        'The Avira Safe Shopping extension works automatically in the background when installed and enabled. The extension shows pop-up notifications when coupons are available.',
      subInfoID: 'migrationPage.faq.item2.subInfo',
      subInfoDefaultMessage:
        'To use a coupon offer, click Apply All Coupons within the notification during checkout or copy the code (highlight and press Ctrl and C keys simultaneously). At checkout, paste the code (press Ctrl and V keys simultaneously) into the field for a discount code. The coupon will then apply savings to your shopping cart and will notify you when you have the best price.',
    },
    {
      headingID: 'migrationPage.faq.item3.heading',
      headingDefaultMessage: 'How does the extension select which coupons to show?',
      infoID: 'migrationPage.faq.item3.info',
      infoDefaultMessage:
        'Avira Safe Shopping extension does its best to provide a broad range of coupons and continuously works to increase coverage. Coupons are selected from a supported list of vendors that match your search, and when using Auto Apply, will automatically detect which Coupon, if available, will provide you with the greatest savings.',
    },
  ];
  return (
    <FAQSectionContainer>
      <FAQSectionHeading>
        <FormattedMessage
          id='migrationPage.faq.heading'
          defaultMessage='Frequently Asked Questions'
        />
      </FAQSectionHeading>
      <FAQsContainer>
        {FAQItems.map((item, index) => (
          <FAQ
            key={index}
            headingID={item.headingID}
            headingDefaultMessage={item.headingDefaultMessage}
            infoID={item.infoID}
            infoDefaultMessage={item.infoDefaultMessage}
            subInfoID={item.subInfoID}
            subInfoDefaultMessage={item.subInfoDefaultMessage}
          />
        ))}
      </FAQsContainer>
    </FAQSectionContainer>
  );
};

export default FAQSection;
