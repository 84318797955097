import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';
import FontSizes from '../../../baseComponents/variables/fontSizes';
import { FontFamily, FontWeight } from '../../../baseComponents/variables/font';
import LineHeightEnum from '../../../baseComponents/variables/lineHeight';

export const PromotionTextHeading = styled('div')`
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.doublePica};
  line-height: ${LineHeightEnum.doublePica};
  font-weight: ${FontWeight.bold};
  letter-spacing: -0.2px;
  color: ${colors.shark};
  width: 252px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  margin-top: 8px;
`;

export const PromotionInfo = styled('div')`
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.english};
  line-height: ${LineHeightEnum.greatPrimer};
  font-weight: ${FontWeight.bold};
  color: ${colors.shark};
  opacity: 0.75;
  width: 252px;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
`;

export const PromotionTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const PromotionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 0;
  width: 316px;
  height: 100%;
`;
