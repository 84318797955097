import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';
import sizes from 'pwm-components/variables/sizes';
import FontSizes from '../../../baseComponents/variables/fontSizes';
import { FontFamily, FontWeight } from '../../../baseComponents/variables/font';
import LineHeightEnum from '../../../baseComponents/variables/lineHeight';
import expandLess from '../../../img/languageSelectorExpand.svg';
import expandLessDark from '../../../img/languageSelectorExpandDark.svg';
import { DropdownMenu } from '../../../baseComponents/Dropdown/DropdownMenu.styles';
// Not from FIGMA

export const FooterContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #424242;
  justify-content: center;
  height: 66px;
  padding: 0 50px;
`;

export const FooterContainer2 = styled('div')`
  width: 1220px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 120px;
`;

export const FooterLinksContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #424242;
  height: 66px;
  color: ${colors.white};
  opacity: 0.75;
`;

export const FooterLink = styled('a')`
  color: ${colors.white};
  opacity: 0.75;
  margin-left: 20px;
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.brevier};
  line-height: ${LineHeightEnum.bourgeois};
  font-weight: ${FontWeight.normal};
  &:hover {
    text-decoration: underline;
  }
`;

export const LanguageSelectorContainer = styled('div')`
  position: relative;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  left: -100px;
`;

export const LanguageSelector = styled('button')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 1px solid ${colors.white};
  border-radius: 2px;
  background: none;
  color: ${colors.white};
  height: 32px;
  width: 97px;
  &:hover {
    color: ${colors.shark};
    background: ${colors.white};
  }
  transition: 0.5s;
`;

export type ExpandLessIconProps = {
  direction: string;
  isHover: boolean;
};

export const ExpandLessIcon = styled('img')<ExpandLessIconProps>`
  height: 12px;
  width: 12px;
  margin-left: 8px;
  margin-top: 2px;
  transform: rotate(${props => props.direction});
  content: url(${props => (props.isHover ? expandLessDark : expandLess)});
  transition: 0.5s;
`;

export const CurrentLanguage = styled('div')`
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.bourgeois};
  line-height: ${LineHeightEnum.bourgeois};
  font-weight: ${FontWeight.normal};
`;

export const LanguageDropup = styled(DropdownMenu)`
  left: 0;
  right: 0;
  top: unset;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: ${sizes.xs};
  min-width: 190px;

  &::after {
    top: unset;
    right: ${sizes.xs};
    transform: rotate(-45deg);
  }
`;
