import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import { useHistory } from 'react-router-dom';
import Button from 'pwm-components/components/Button';
import FocusTrap from 'focus-trap-react';
import { useMixpanel } from '../hooks';

const Modal = styled(Box)`
position: fixed;
top: 0;
left: 0;
width:100%;
height: 100%;
background: rgba(0, 0, 0, 0.6);
`;

const ModalMain = styled(Box)`
position:fixed;
background: #1B2428;
width: 43%;
height: 269px;
top:50%;
left:50%;
transform: translate(-50%,-50%);
padding:30px;
border-radius: 4px;
@media only screen and (max-width: 1200px) {
  width: 60%;
 }
 @media only screen and (max-width: 800px) {
  width: 80%;
 }
 @media only screen and (max-width: 600px) {
  width: 90%;
  height: fit-content;
 }
`;

const CloseButton = styled(Button)`
text-align: center;
width: 30%;
margin-left: 35%;
margin-right: 35%;
background-color: #199FFF;
color: #FFFFFF;
border-radius: 4px;
margin-bottom: 12px;
font-size: 16px;
line-height: 40px;
:hover {
  background-color: #0289CC;
}
:focus {
  background-color: #0289CC;
  box-shadow: none !important;
}
@media only screen and (max-width: 530px) {
 width: 60%;
 margin-left: 20%;
 margin-right: 20%;
 }
`;

const RedirectButton = styled(Button)`
text-align: center;
width: 30%;
margin-left: 35%;
margin-right: 35%;
color: #FFFFFF;
line-height: 40px;
background-color: transparent;
font-size: 16px;
:hover {
  background-color: transparent;
}
:focus {
  background-color: transparent;
  border: 1px #FFFFFF solid;
}
@media only screen and (max-width: 530px) {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}
`;

const ParagraphTitle = styled(Paragraph)`
color: #FFFFFF;
font-size: 18px;
width: 60%;
margin-right: 20%;
margin-left: 20%;
padding-bottom: 20px;
cursor: default;
`;


export type Props = {
  show: boolean;
  closeClick: (value: boolean) => void;
};

const CloseModal: React.FC<Props> = ({
  show,
  closeClick,
}) => {
  const history = useHistory();
  const mixpanel = useMixpanel();

  const redirectClick = (): void => {
    mixpanel.trackXDialogNoClicked();
    history.push('/');
  };

  return (
    <Box>
      {show && (
        <>
          <FocusTrap>
            <Modal>
              <ModalMain className="modal-main">
                <ParagraphTitle my="m" textAlign="center" size="h3">
                  <FormattedMessage
                    id="getSafeSearch.modal.title"
                    defaultMessage="Are you sure you don't want to search the web more safely with Avira Safe Search?"
                  />
                </ParagraphTitle>
                <Box width='100%'>
                  <CloseButton
                    onClick={() => closeClick(false)}
                    placeholder=""
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    <FormattedMessage
                      id="getSafeSearch.modal.close"
                      defaultMessage="Yes, I want to"
                    />
                  </CloseButton>
                </Box>
                <Box width='100%'>
                  <RedirectButton
                    onClick={redirectClick}
                    placeholder=""
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    <FormattedMessage
                      id="getSafeSearch.modal.redirect"
                      defaultMessage="No, exit now"
                    />
                  </RedirectButton>
                </Box>
              </ModalMain>
            </Modal>
          </FocusTrap>
        </>
      )}
    </Box>
  );
};

export default CloseModal;
