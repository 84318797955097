import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';
import FontSizes from '../../../baseComponents/variables/fontSizes';
import { FontFamily, FontWeight } from '../../../baseComponents/variables/font';
import LineHeightEnum from '../../../baseComponents/variables/lineHeight';

type InfoStepContainerProps = {
  direction: string;
};

export const InfoStepContainer = styled('div')<InfoStepContainerProps>`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1220px;
  margin-top: 48px;
`;

export const InfoStepHeading = styled('div')`
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.doubleColumbianExchange};
  line-height: ${LineHeightEnum.quadrupleColumbian};
  font-weight: ${FontWeight.bold};
  letter-spacing: -0.2px;
  color: ${colors.shark};
  width: 560px;
  margin-bottom: 21px;
`;

export const InfoStepInfo = styled('div')`
  display: flex;
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.english};
  line-height: ${LineHeightEnum.greatPrimer};
  font-weight: ${FontWeight.semibold};
  color: ${colors.shark};
  opacity: 0.75;
  width: 560px;
  margin: 14px 0;
`;

type InfoStepDataProps = {
  posAdjust: string;
};

export const InfoStepData = styled('div')<InfoStepDataProps>`
  & > * {
    display: inline-block;
  }
  position: relative;
  top: ${props => props.posAdjust};
`;

export const InfoStepBullet = styled('div')`
  margin: 0 10px;
`;
