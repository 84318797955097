import styled, { css } from 'styled-components';
import colors from 'pwm-components/variables/colors';
import TextButton from 'pwm-components/components/TextButton';
import {
  maxWidth,
  MaxWidthProps,
  width,
  WidthProps,
} from 'styled-system';
import sizes from 'pwm-components/variables/sizes';
import { DropdownMenuItem } from './DropdownMenuItem.styles';

export type Props = {
  hidden?: boolean;
  arrowSize?: number;
  actionMenu?: boolean;
  align?: 'left' | 'right';
  allowOverflow?: boolean;
} & WidthProps & MaxWidthProps;

export const DropdownMenu = styled('ul')<Props>`
  background-color: ${colors.white};
  border-radius: ${sizes.xxs};
  border: 1px solid ${colors.gainsboro};
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.08);
  margin-top: ${({ arrowSize }) => (arrowSize === 0 ? '1px' : sizes.xs)};
  position: absolute;
  ${maxWidth}
  ${width}
  ${({ align, arrowSize }) => css`
    ${align}: ${arrowSize ? `-${sizes.xs}` : 0};
  `}
  ${({
    allowOverflow,
  }) => (allowOverflow && css`
    min-width: 100%;
  `)}

  top: 100%;
  z-index: 30;
  ${({ hidden }) => hidden && css`
    display: 'none';
  `}

  ${({ actionMenu, allowOverflow }) => actionMenu && css`
    padding-top: ${sizes.xxs};
    padding-bottom: ${sizes.xs};

    ${DropdownMenuItem} {
      font-size: ${({ theme }) => theme.fontSizes.longPrimer};

      & > ${TextButton}, & > a, & > div {
        padding: ${sizes.xs};
        ${(allowOverflow && css`
          min-width: 100%;
          width: fit-content;
        `)}
      }
    }
  `}

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: 2px;
    box-sizing: border-box;
    border: ${({ arrowSize }) => `${arrowSize}px solid black`};
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -1px 1px 0 0 #e6eaec;
  }
`;

DropdownMenu.defaultProps = {
  hidden: true,
  arrowSize: 6,
  actionMenu: false,
  align: 'right',
};
