import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';
import FontSizes from '../../../baseComponents/variables/fontSizes';
import { FontFamily, FontWeight } from '../../../baseComponents/variables/font';
import LineHeightEnum from '../../../baseComponents/variables/lineHeight';

export const InfoContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-bottom: 88px;
  padding-right: 110px;
  padding-left: 110px;
`;
export const InfoHeading = styled('div')`
  font-family: ${FontFamily.OpenSans};
  font-size: ${FontSizes.Canon};
  line-height: ${LineHeightEnum.quadrupleColumbian};
  font-weight: ${FontWeight.bold};
  letter-spacing: -0.2px;
  color: ${colors.shark};
`;
