import React from 'react';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import useResponsiveWidth from 'pwm-components/hooks/useResponsiveWidth';
import AviraLogoLight from '../../img/avira-logo-light.svg';
import AviraLogoDark from '../../img/avira-logo-dark.svg';

const HeaderContainer = styled('div')<{ screenWrap?: boolean }>`
  width: 100%;
  padding: ${({ theme: { space } }) => space.l};
  margin-bottom: ${({ theme: { space } }) => space.xxxl};
  ${({ screenWrap, theme: { mode, semanticColors, colors } }) => (screenWrap ? `
    background: ${semanticColors.foreground};
    border-bottom: solid 1px ${mode === 'light' ? colors.gainsboro : semanticColors.foreground};
  ` : '')};
`;

const AviraLogo = styled('img').attrs(() => ({ alt: 'Avira' }))`
  content: url(${({ theme: { mode } }) => (mode === 'light' ? AviraLogoDark : AviraLogoLight)});
`;

const Header: React.FC = () => {
  const screen = useResponsiveWidth();
  let width = 0;
  switch (screen) {
    case 'mobile':
      width = 720;
      break;
    case 'tablet':
      width = 960;
      break;
    default:
      width = 1180;
      break;
  }

  return (
    <HeaderContainer screenWrap={screen === 'mobile' || screen === 'tablet'}>
      <Box display="flex" justifyContent="flex-start" alignItems="center" width={width} m="auto">
        <AviraLogo />
      </Box>
    </HeaderContainer>
  );
};

export default Header;
