enum FontSizes {
  diamond = '7px',
  pearl = '8px',
  brevier = '11px',
  bourgeois = '12px',
  smallPrimer = '13px',
  longPrimer = '14px',
  pica = '16px',
  english = '18px',
  littleThree = '19px',
  columbianExchange = '20px',
  greatPrimer = '24px',
  doubleLongPrimer = '28px',
  doublePica = '32px',
  doubleEnglish = '36px',
  doubleColumbianExchange = '40px',
  doubleGreatPrimer = '48px',
  Canon = '56px',
}

export declare type FontSizesType = typeof FontSizes;
export declare type FontSizeKey = keyof FontSizesType;
export default FontSizes;
