import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import theme from 'pwm-components/theme';
import GlobalStyle, { DEFAULT_CONFIG, FontFaces } from 'pwm-components/global';
import ThemeProvider from 'pwm-components/ThemeProvider';
import { ViewportProvider } from 'pwm-components/hooks/useViewport';

import LocaleContainer from '../locale/LocaleContainer';
import ABTest from './ABTest';
import Mixpanel from './contexts/Mixpanel';
import BrowserConfig from './contexts/BrowserConfig';
import Uninstall from './uninstall/Uninstall';
import GetABS from './abs/GetABS';
import GetSafeSearch from './safesearch/GetSafeSearch';
import MigrationPage from './safeShopping/MigrationPage';
import Main from './main/Main';
import ExtensionInstallState from './contexts/ExtensionInstallState';
import EmbargoCheck from './EmbargoCheck/EmbargoCheck';


export default class App extends React.Component {
  render() {
    const { match: { params: { reason, origin: source, timesShown } } } = this.props;

    return (
      <BrowserConfig>
        <ExtensionInstallState>
          <ThemeProvider theme={{ ...theme }} mode="light">
            <GlobalStyle />
            <FontFaces config={DEFAULT_CONFIG} />
            <ViewportProvider>
              <LocaleContainer>
                <Mixpanel source={source} reason={reason} timesShown={parseInt(timesShown, 10)}>
                  <ABTest>
                    <EmbargoCheck>
                      <Switch>
                        <Route
                          path="/(pwm|abs|sse)/uninstall"
                          component={() => <Uninstall extension={source} />}
                        />
                        <Route
                          path="/get-abs"
                          component={GetABS}
                        />
                        <Route path="/get-safesearch"
                          component={GetSafeSearch}
                        />
                        <Route path="/safe-shopping-migration"
                          component={MigrationPage}
                        />
                        <Route
                          path="*"
                          component={Main}
                        />
                      </Switch>
                    </EmbargoCheck>
                  </ABTest>
                </Mixpanel>
              </LocaleContainer>
            </ViewportProvider>
          </ThemeProvider>
        </ExtensionInstallState>
      </BrowserConfig>
    );
  }
}


App.propTypes = {
  source: PropTypes.string,
  reason: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,
};
