import Card from 'pwm-components/components/Card';
import Loading from 'pwm-components/components/Loading';
import Paragraph from 'pwm-components/components/Paragraph';
import ErrorIcon from 'pwm-components/icons/Error';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Background from '../Background';
import CountryContext from '../contexts/Country';

const EmbargoCheck: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [embargoed, setEmbargoed] = useState(false);
  const [countryCode, setCountryCode] = useState<null|string>(null);

  useEffect(() => {
    fetch('https://api.my.avira.com/v2/ping')
      .then((response) => {
        setCountryCode(response.headers.get('X-Avira-Country-Code'));
        setLoading(false);
      })
      .catch((error) => {
        if (error.httpCode === 451) {
          setEmbargoed(true);
        }
        setLoading(false);
      });
  }, [setLoading, setEmbargoed]);

  if (loading) {
    return (
      <Loading />
    );
  }
  if (embargoed) {
    return (
      <Background>
        <Card width={500} margin="auto" marginTop="xxl">
          <Paragraph mb="l" textAlign="center">
            <ErrorIcon variant='alert' size="large" />
          </Paragraph>
          <FormattedMessage
            id="embargo.error.description.geolocation"
            defaultMessage="It is not possible to use Avira products in your country. Avira runs automatic checks to ensure we don't export products in breach of government regulations." />
        </Card>
      </Background>
    );
  }
  return (
    <CountryContext.Provider value={countryCode}>
      {children}
    </CountryContext.Provider>
  );
};

export default EmbargoCheck;
