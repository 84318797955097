import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  PromotionInfo,
  PromotionTextHeading,
  PromotionContainer,
  PromotionTextContainer,
} from './Promotion.styles';

const Promotion: React.FC<{
  imageURL: string;
  headingID: string;
  headingDefaultMessage: string;
  infoID: string;
  infoDefaultMessage: string;
}> = ({
  imageURL,
  headingID,
  headingDefaultMessage,
  infoID,
  infoDefaultMessage,
}) => (
  <PromotionContainer>
    <img alt="Promotion" src={imageURL} />
    <PromotionTextContainer>
      <PromotionTextHeading>
        <FormattedMessage
          id={headingID}
          defaultMessage={headingDefaultMessage}
        />
      </PromotionTextHeading>
      <PromotionInfo>
        <FormattedMessage
          id={infoID}
          defaultMessage={infoDefaultMessage}
        />
      </PromotionInfo>
    </PromotionTextContainer>
  </PromotionContainer>
);

export default Promotion;
