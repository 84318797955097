import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Button, { Props as ButtonProps } from 'pwm-components/components/Button';

import chrome from '../img/chrome@1x.png';
import chrome2x from '../img/chrome@2x.png';
import chrome3x from '../img/chrome@3x.png';

import edge from '../img/edge@1x.png';
import edge2x from '../img/edge@2x.png';
import edge3x from '../img/edge@3x.png';

import Firefox from '../img/Firefox@1x.png';
import Firefox2x from '../img/Firefox@2x.png';
import Firefox3x from '../img/Firefox@3x.png';

import Opera from '../img/Opera@1x.png';
import Opera2x from '../img/Opera@2x.png';
import Opera3x from '../img/Opera@3x.png';

import { ExtensionKey } from './ExtensionTypes';
import { useMixpanel, useBrowserConfig } from './hooks';

export type Props = Pick<ButtonProps, 'variant'> & {
  type: ExtensionKey;
  showBrowserName?: boolean;
  children?: React.ReactNode;
};

const AddToButton: React.FC<Props> = ({
  type,
  children,
  variant = 'primary',
  showBrowserName = false,
}) => {
  const { storeURLs, browser } = useBrowserConfig();
  const [browserImage, setBrowserImage] = useState<JSX.Element>();
  const mixpanel = useMixpanel();

  useEffect(() => {
    switch (browser) {
      case 'Chrome':
        setBrowserImage(
          <img
            alt="chrome"
            src={chrome}
            srcSet={`${chrome} 1x, ${chrome2x} 2x, ${chrome3x} 3x`}
          />,
        );
        break;
      case 'Firefox':
        setBrowserImage(
          <img
            alt="Firefox"
            src={Firefox}
            srcSet={`${Firefox} 1x, ${Firefox2x} 2x, ${Firefox3x} 3x`}
          />,
        );
        break;
      case 'Opera':
        setBrowserImage(
          <img
            alt="Opera"
            src={Opera}
            srcSet={`${Opera} 1x, ${Opera2x} 2x, ${Opera3x} 3x`}
          />,
        );
        break;
      case 'Edge':
        setBrowserImage(
          <img
            alt="edge"
            src={edge}
            srcSet={`${edge} 1x, ${edge2x} 2x, ${edge3x} 3x`}
          />,
        );
        break;
      default:
        break;
    }
  }, [browser, type]);

  const handleClick = useCallback((): void => {
    mixpanel.trackAddToButton(type);
    window.open(storeURLs[type]);
  }, [mixpanel, type, storeURLs]);

  return (
    <Button
      fullWidth
      disabled={!storeURLs[type]}
      onClick={handleClick}
      variant={variant}
      placeholder=""
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      {children || (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box mr="xs">
            <FormattedMessage
              id="addToBrowserButton.extensionInstalled"
              defaultMessage="ADD TO"
            />
          </Box>
          {browserImage}
          {showBrowserName && (<Box ml="xs">{browser.toUpperCase()}</Box>)}
        </Box>
      )}
    </Button>
  );
};

export default AddToButton;
