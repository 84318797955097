import React from 'react';
import { FormattedMessage } from 'react-intl';

import Card from 'pwm-components/components/Card';
import Paragraph from 'pwm-components/components/Paragraph';
import Box from 'pwm-components/components/Box';


import chromeBlack from '../../img/chromeBlack@1x.png';
import chromeBlack2x from '../../img/chromeBlack@2x.png';
import chromeBlack3x from '../../img/chromeBlack@3x.png';

import edgeBlack from '../../img/edgeBlack@1x.png';
import edgeBlack2x from '../../img/edgeBlack@2x.png';
import edgeBlack3x from '../../img/edgeBlack@3x.png';

import FirefoxBlack from '../../img/FirefoxBlack@1x.png';
import FirefoxBlack2x from '../../img/FirefoxBlack@2x.png';
import FirefoxBlack3x from '../../img/FirefoxBlack@3x.png';

import OperaBlack from '../../img/OperaBlack@1x.png';
import OperaBlack2x from '../../img/OperaBlack@2x.png';
import OperaBlack3x from '../../img/OperaBlack@3x.png';

const BrowserNotSupported = () => (
  <Card mt="xs" maxWidth="669px">
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      m="zero"
    >
      <Box textAlign="center" px="xxxl" py="xl">
        <Paragraph size="h2">
          <FormattedMessage
            id="browserNotSupported.message"
            defaultMessage="Unfortunately, your current browser is not supported."
          /> &nbsp;
          <FormattedMessage
            id="browserNotSupported.chooseBrowser"
            defaultMessage="Select the browser you want to use with the Avira extensions:"
          />
        </Paragraph>
      </Box>
      <Box
        textAlign="center"
        display="flex"
        alignItems="center"
      >
        <Box m="l" display="flex" flexDirection="column">
          <a
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={chromeBlack}
              srcSet={`${chromeBlack} 1x, ${chromeBlack2x} 2x, ${chromeBlack3x} 3x`}
              alt="chromeBlack"
            />
          </a>
          <FormattedMessage
            id="browserNotSupported.chooseBrowser.chrome"
            defaultMessage="Chrome"
          />
        </Box>
        <Box m="l" display="flex" flexDirection="column">
          <a
            href="https://www.microsoft.com/edge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={edgeBlack}
              srcSet={`${edgeBlack} 1x, ${edgeBlack2x} 2x, ${edgeBlack3x} 3x`}
              alt="edgeBlack"
            />
          </a>
          <FormattedMessage
            id="browserNotSupported.chooseBrowser.edge"
            defaultMessage="Edge"
          />
        </Box>
        <Box m="l" display="flex" flexDirection="column">
          <a
            href="https://www.mozilla.org/firefox/new/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={FirefoxBlack}
              srcSet={`${FirefoxBlack} 1x, ${FirefoxBlack2x} 2x, ${FirefoxBlack3x} 3x`}
              alt="FirefoxBlack"
            />
          </a>
          <FormattedMessage
            id="browserNotSupported.chooseBrowser.firefox"
            defaultMessage="Firefox"
          />
        </Box>
        <Box m="l" display="flex" flexDirection="column">
          <a
            href="https://www.opera.com/download"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={OperaBlack}
              srcSet={`${OperaBlack} 1x, ${OperaBlack2x} 2x, ${OperaBlack3x} 3x`}
              alt="OperaBlack"
            />
          </a>
          <FormattedMessage
            id="browserNotSupported.chooseBrowser.opera"
            defaultMessage="Opera"
          />
        </Box>
      </Box>
    </Box>
  </Card>
);

export default BrowserNotSupported;
