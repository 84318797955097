import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from 'pwm-components/components/Box';
import { InfoContainer, InfoHeading } from './Info.styles';
import InfoStep, { flowDirection } from './InfoStep';
import infoWindowOne from '../../../img/infoWindowOne.svg';
import infoWindowTwo from '../../../img/infoWindowTwo.svg';
import redCart from '../../../img/redCart.svg';
import magicClick from '../../../img/magicClick.svg';
import { LocaleContext } from '../../../locale/LocaleContainer';

const Info: React.FC = () => {
  const { locale: currLocale } = useContext(LocaleContext);
  return (
    <InfoContainer>
      <InfoHeading>
        <FormattedMessage
          id='migration.info.heading'
          defaultMessage='How it works?'
        />
      </InfoHeading>
      <InfoStep
        imagePosition={flowDirection.right}
        imageURL={infoWindowOne}
        headingID='migration.info.step.one.heading'
        headingDefaultMessage='Find coupons online while staying protected'
        infoIDs={['migration.info.step.one.info']}
        infoDefaultMessages={[
          'The Avira Safe Shopping lets you know you’re shopping at a trusted store!',
        ]}
        infoImages={[
          <Box marginRight={6} key={0} position='relative' top='8px'>
            <img src={redCart} alt='Avira Safe Shopping' />
          </Box>,
        ]}
        posAdjust={['-8px']}
      />
      <InfoStep
        imagePosition={flowDirection.left}
        imageURL={infoWindowTwo}
        headingID='migration.info.step.two.heading'
        headingDefaultMessage='Save money at thousands of online shops'
        infoIDs={[
          'migration.info.step.two.info.one',
          'migration.info.step.two.info.two',
          'migration.info.step.two.info.three',
        ]}
        infoDefaultMessages={[
          'Just one click',
          'No more copy and pasting!',
          'Safe Shopping will automatically find and apply codes for you at checkout!',
        ]}
        infoImages={[
          <Box
            key={0}
            marginLeft={currLocale === 'ja-JP' ? 0 : 8}
            marginRight={8}
            position='relative'
            top='5px'
          >
            <img src={magicClick} alt='img' />
          </Box>,
          <></>,
          <></>,
        ]}
        posAdjust={['-5px', '0', '0']}
      />
    </InfoContainer>
  );
};

export default Info;
