import React from 'react';
import styled from 'styled-components';
import AviraLogoSVG from '../../../img/aviraLogo.svg';

const AviraLogo = styled('img').attrs(() => ({ alt: 'Avira' }))`
    margin-left: 40px;
    margin-top: 4px;
    content: url(${AviraLogoSVG});
`;

const Header: React.FC = () => (
  <div>
    <AviraLogo />
  </div>
);

export default Header;
