import common from './common.json';
import development from './development.json';
import production from './production.json';

export type Extension = {
  id: string;
  name?: string;
}

export type BrowserConfig = {
  storeLink: string;
  extensions: { [key: string]: Extension };
}

export type Config = {
  hostConfig: { authHost: string };
  authConfig: { authSecret: string; client: string };
  mixpanelProjectToken: string;
  browserConfigs: { [key: string]: BrowserConfig };
}

// eslint-disable-next-line import/no-mutable-exports
let config: Config;
switch (process.env.NODE_ENV) {
  case 'development':
  case 'test':
    config = {
      ...common,
      ...development,
    };
    break;
  case 'production':
  default:
    config = {
      ...common,
      ...production,
    };
    break;
}

export default config;
