import React, { useCallback, useEffect, useState } from 'react';

import { ExtensionKey } from '../../ExtensionTypes';
import useBrowserConfig from '../../hooks/useBrowserConfig';

export type Props = { [T in ExtensionKey]: boolean };

const defaultInstallState = {
  pwm: false,
  abs: false,
  sse: false,
  safesearch: false,
};

export const ExtensionInstalledContext = React.createContext<Props>(defaultInstallState);

const NSSS_INSTALL_SUCCESS = 'NSSSInstallSuccess';
const COUPON_INSTALL_SUCCESS = 'AviraSafeShoppingInstallSuccess';
type ExtnInstallSuccessPayload = {
  detail: string;
}
let ExtnInstallSuccessEvents: ExtnInstallSuccessPayload[] = [];

// Since the event is send before this component mounts collecting events here first
const ExtnInstallSuccessPreHandler = (event: ExtnInstallSuccessPayload): void => {
  ExtnInstallSuccessEvents.push(event);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
document.addEventListener(NSSS_INSTALL_SUCCESS, ExtnInstallSuccessPreHandler);
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
document.addEventListener(COUPON_INSTALL_SUCCESS, ExtnInstallSuccessPreHandler);

const ExtensionInstallState: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { extensions } = useBrowserConfig();
  const [installState, setInstallState] = useState(defaultInstallState);

  useEffect(() => {
    const ExtnInstallSuccessHandler = ({ detail }: ExtnInstallSuccessPayload): void => {
      Object.entries(extensions).forEach(([type, extension]) => {
        if (extension.id === detail) {
          setInstallState(state => ({ ...state, [type]: true }));
        }
      });
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    document.removeEventListener(NSSS_INSTALL_SUCCESS, ExtnInstallSuccessPreHandler);
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    document.removeEventListener(COUPON_INSTALL_SUCCESS, ExtnInstallSuccessPreHandler);
    ExtnInstallSuccessEvents.forEach(ExtnInstallSuccessHandler);
    ExtnInstallSuccessEvents = [];

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    document.addEventListener(NSSS_INSTALL_SUCCESS, ExtnInstallSuccessHandler);
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    document.addEventListener(COUPON_INSTALL_SUCCESS, ExtnInstallSuccessHandler);
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      document.removeEventListener(NSSS_INSTALL_SUCCESS, ExtnInstallSuccessHandler);
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      document.removeEventListener(COUPON_INSTALL_SUCCESS, ExtnInstallSuccessHandler);
    };
  }, [extensions]);

  const checkBodyClassList = useCallback(() => {
    Object.keys(extensions).forEach((type) => {
      if (document.body.classList.contains(`${type}-installed`)) {
        setInstallState(state => ({ ...state, [type]: true }));
      }
    });
  }, [extensions]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'attributes') {
          checkBodyClassList();
        }
      }
    });

    observer.observe(document.body, { attributeFilter: ['class'] });
    checkBodyClassList();

    return () => {
      observer.disconnect();
    };
  }, [checkBodyClassList]);

  return (
    <ExtensionInstalledContext.Provider value={installState}>
      { children }
    </ExtensionInstalledContext.Provider>
  );
};

export default ExtensionInstallState;
