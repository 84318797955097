import styled from 'styled-components';
import colors from 'pwm-components/variables/colors';

export const MigrationBodyContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Banner = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.shark};
  min-height: 112px;
  padding-left: 70px;
  padding-right: 70px;
`;

export const PromotionsContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  background-color: ${colors.alabaster};
  min-height: 256px;
`;
