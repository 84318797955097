import React from 'react';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import background from '../img/background@1x.png';
import Header from './layout/Header';
import Footer from './layout/Footer';

const BackgroundContainer = styled('div')`
  position: relative;
  width: 100%;
  min-width: 375px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BackgroundImage = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url(${background});
  background-size: cover;
`;

const Background: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <BackgroundContainer>
    <BackgroundImage />
    <Header />
    <Box px="l" flex="1 1 auto" position="relative">
      {children}
    </Box>
    <Footer />
  </BackgroundContainer>
);

export default Background;
